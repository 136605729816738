import React, { createContext, useState, useContext } from 'react';

const CurationMapContext = createContext();
const CurationMapContentContext = createContext();
const CurationMapHoverContext = createContext();

export const useCurationMapContext = () => {
  return useContext(CurationMapContext);
};

export const useCurationMapContentContext = () => {
  return useContext(CurationMapContentContext);
};

export const useCurationMapHoverContext = () => {
  return useContext(CurationMapHoverContext);
};

export const CurationMapProvider = ({ children }) => {
  const [curationMapConfig, setCurationMapConfig] = useState(null);

  return (
    <CurationMapContext.Provider value={{ curationMapConfig, setCurationMapConfig }}>
      {children}
    </CurationMapContext.Provider>
  );
};

export const CurationMapContentProvider = ({ children }) => {
  const [curationMapPopup, setCurationMapPopup] = useState(null);

  return (
    <CurationMapContentContext.Provider value={{ curationMapPopup, setCurationMapPopup }}>
      {children}
    </CurationMapContentContext.Provider>
  );
};

export const CurationMapHoverProvider = ({ children }) => {
  const [curationHoverId, setCurationHoverId] = useState(null);

  return (
    <CurationMapHoverContext.Provider value={{ curationHoverId, setCurationHoverId }}>
      {children}
    </CurationMapHoverContext.Provider>
  );
};