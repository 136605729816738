import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PlaceDetailsProvider } from './pages/placedetails/PlaceDetailsContext';
import { CurationProvider } from './pages/curation/CurationContext';
import { CurationPlacesProvider } from './pages/curation/CurationPlacesContext';
import { CurationScrollStateProvider } from './pages/curation/CurationScrollStateContext';
import { CurationMembersProvider } from './pages/curation/CurationMembersContext';
import { CurationMapContentProvider, CurationMapHoverProvider, CurationMapProvider } from './pages/curation/CurationMapContext';
import { AddCurationProvider } from './pages/addcuration/AddCurationContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CurationProvider>
    <AddCurationProvider>
    <CurationScrollStateProvider>
    <CurationMapProvider>
    <CurationMapContentProvider>
    <CurationMapHoverProvider>
    <CurationMembersProvider>
    <CurationPlacesProvider>
    <PlaceDetailsProvider>
    <App />
    </PlaceDetailsProvider>
    </CurationPlacesProvider>
    </CurationMembersProvider>
    </CurationMapHoverProvider>
    </CurationMapContentProvider>
    </CurationMapProvider>
    </CurationScrollStateProvider>
    </AddCurationProvider>
    </CurationProvider>
);

if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.setAttribute('data-theme', 'roambearDark');
} else {
    document.documentElement.setAttribute('data-theme', 'roambearLight');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
